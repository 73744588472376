import type { ConferenceVotingRound, ConferenceVotingRoundReport, Locale } from "@/types";

export function calculateState(votingRound: ConferenceVotingRound, votingRoundReport: ConferenceVotingRoundReport = null) {
  if (votingRound.archived === true) return "archived"
  if (votingRoundReport && votingRoundReport.status === "finished") return "finished"
  if (votingRound.status !== "scheduled" && votingRound.closedAt === null && votingRound.openedAt === null) return "available"
  if (votingRound.status !== "scheduled") return votingRound.status;
  if (!votingRound.schedule) return "closed";

  const fromTimestamp = new Date(votingRound.schedule.from).getTime();
  const toTimestamp = new Date(votingRound.schedule.to).getTime();
  const today = new Date().getTime();

  switch (true) {
    case fromTimestamp > today:
      return "future";
    case toTimestamp < today:
      return "past";
    default:
      return "open";
  }
}

export function label(votingRound: ConferenceVotingRound, locale: Locale) {
  if (!votingRound) return ""
  return votingRound.title?.[locale] || votingRound.name || votingRound.reference;
}
